import { IS_DARK_MODE_KEY } from '../constants';

export const getTheme = () => {
  if (typeof window === 'undefined') {
    return 'dark';
  }
  const isDarkTheme = localStorage.getItem(IS_DARK_MODE_KEY);

  if (isDarkTheme === 'false') {
    return 'classic';
  }
  return 'dark';
};
