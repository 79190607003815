/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';
import styled from '@emotion/styled';

import moment from 'moment-timezone';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Subheader from '../components/Subheader';
import { Counter } from '../components/Counter';
import { getTheme } from '../utility/theme';

const Row = styled.div`
  margin-bottom: 3rem;
`;

interface Props {
  pageContext: {
    lastDeployed: string;
  };
}
interface State {
  isDarkMode: boolean;
}

class Exam extends React.Component<Props, State> {
  render() {
    const date = moment('13/04/2020', 'DD/MM/YYYY');
    const theme = getTheme();

    return (
      <Layout>
        <SEO title="Baby Moi" />
        <Subheader crumbs={[]} />
        <h1>Baby Moi</h1>
        <p>{date.format('dddd Do MMMM YYYY')}</p>

        <Row>
          <p>Time until baby:</p>
          <Counter date={date} theme={theme} />
        </Row>
      </Layout>
    );
  }
}

export default Exam;
